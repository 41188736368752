import React, { useState, useEffect, useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import API from '../context/axioSetup';
import { EmployeesContext, EventsContext } from '../context/EveMitContext';
import RepeatKomponent from './RepeatKomponent';
import Select from 'react-select';
import {
  FormContainer,
  FormHeader1,
  InputEv,
  Button,
  Header,
  DataContainerDatePicker,
  DataContainer,
  StyledDatePicker
} from './EventForm.element';
import useEmployeeSelection from '../Hooks/useEmployeeSelection';
function EventEdit({ hoveredEvent, setShowEventEdit, setNewEvent }) {
  const { employees } = useContext(EmployeesContext);
  const { fetchEventsAndUpdate } = useContext(EventsContext);

  const [isAllDay, setIsAllDay] = useState(false);
  const [showRepeatComponent, setShowRepeatComponent] = useState(false);
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    selectedEmployees: [],
    startTime: new Date(),
    endTime: new Date(),
    color: ''
  });
  const [notification, setNotification] = useState({ message: '', type: '' });
  const {   generateEventColor } = useEmployeeSelection([], employees);
  useEffect(() => {
    if (hoveredEvent) {
      const { title, description, employee_names, start_datetime, end_datetime, color } = hoveredEvent;
      setEventData({
        title,
        description,
        color,
        selectedEmployees: employee_names || [],
        startTime: start_datetime ? new Date(start_datetime) : new Date(),
        endTime: end_datetime ? new Date(end_datetime) : new Date(),
      });
    }
  }, [hoveredEvent]);

  useEffect(() => {
    if (isAllDay) {
      const startOfDay = new Date(eventData.startTime);
      startOfDay.setHours(0, 0, 0, 0);
      const nextDay = new Date(startOfDay);
      nextDay.setDate(nextDay.getDate() + 1);

      setEventData(prevData => ({ ...prevData, startTime: startOfDay, endTime: nextDay }));
    }
  }, [isAllDay, eventData.startTime]);

  const handleDateChange = (date, field) => {
    if (date) {
      setEventData(prevData => ({
        ...prevData,
        [field]: date,
        ...(field === 'startTime' && { endTime: prevData.endTime < date ? new Date(date.getTime() + 60 * 60 * 1000) : prevData.endTime })
      }));
    }
  };

  const handleEditButtonClick = async () => {
    const formattedStartDate = format(eventData.startTime, 'yyyy-MM-dd HH:mm:ss', { locale: de });
    const formattedEndDate = format(eventData.endTime, 'yyyy-MM-dd HH:mm:ss', { locale: de });

    const editedEventData = {
      event_id: hoveredEvent.event_id,
      start: formattedStartDate,
      end: formattedEndDate,
      title: eventData.title,
      description: eventData.description,
      selectedEmployees: eventData.selectedEmployees,
      color: generateEventColor(eventData.selectedEmployees),
    };

    try {
      const response = await API.post('/editEvent', editedEventData);

      if (response.status === 200) {
        setNotification({ message: response.data.message, type: 'success' });
        fetchEventsAndUpdate();
      } else {
        setNotification({ message: 'Fehler beim Bearbeiten des Events', type: 'error' });
      }
    } catch (error) {
      setNotification({ message: `Fehler beim Bearbeiten des Events: ${error.message}`, type: 'error' });
    }

    setShowEventEdit(false);
  };

  const handleRepeatCheckbox = (e) => setShowRepeatComponent(e.target.checked);
  const handleAllDayCheckbox = (e) => setIsAllDay(e.target.checked);

  const employeeOptions = employees.map(employee => ({
    value: employee.name,
    label: employee.name,
    color: employee.farbe,
    id: employee.mitarbeiter_id
  }));
  const handleDeleteEvent = async () => {
    const eventID = hoveredEvent.event_id;

    const isConfirmed = window.confirm('Möchten Sie diesen Termin wirklich löschen?');
    if (isConfirmed) {
      try {
        const response = await API.delete(`/deleteEvent`, {
          data: { eventID: eventID },
          withCredentials: true
        });

        if (response.status === 200) {
          setNotification({ message: response.data.message, type: 'success' });  // Erfolgsbenachrichtigung setzen
          fetchEventsAndUpdate();  // Event-Liste aktualisieren
          console.log(notification);
        } else {
          setNotification({ message: 'Fehler beim Löschen des Events', type: 'error' });  // Fehlerbenachrichtigung setzen
        }
      } catch (error) {
        setNotification({ message: 'Fehler beim Löschen des Events: ' + error.message, type: 'error' });  // Fehlerbenachrichtigung setzen
      }

      setShowEventEdit(false); // Schließt das Bearbeitungsfenster nach dem Löschen
    }
  };


  return (
    <FormContainer showrepeatComponent={showRepeatComponent}>
      <FontAwesomeIcon icon={faTimes} onClick={() => setShowEventEdit(false)} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
      <Header>
        <FormHeader1>Bearbeite deinen Termin</FormHeader1>
      </Header>
      <InputEv type="text" placeholder="Titel" value={eventData.title} onChange={(e) => setEventData({ ...eventData, title: e.target.value })} />
      <InputEv type="text" placeholder="Description" value={eventData.description} onChange={(e) => setEventData({ ...eventData, description: e.target.value })} />
      
      <Select
        options={employeeOptions}
        value={eventData.selectedEmployees.map(employee => ({
          value: employee.name,
          label: employee.name,
          color: employee.color,
          id: employee.id
        }))}
        onChange={(selectedOptions) => {
          const selectedEmployees = selectedOptions.map(option => {
            const employee = employees.find(emp => emp.mitarbeiter_id === option.id);
            return { name: employee.name, color: employee.farbe, id: employee.mitarbeiter_id };
          });
          setEventData({ ...eventData, selectedEmployees });
        }}
        isMulti
        placeholder="Welche Mitarbeiter"
        getOptionLabel={(option) => (
          <div style={{ backgroundColor: option.color }}>{option.label}</div>
        )}
        getOptionValue={(option) => option.value}
      />
      
      <DataContainerDatePicker showrepeatComponent={showRepeatComponent}>
        <div>
          Von:
          <StyledDatePicker
            selected={eventData.startTime}
            onChange={(date) => handleDateChange(date, 'startTime')}
            showTimeSelect={!isAllDay}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Zeit"
            dateFormat="Pp"
            locale="de"
          />
        </div>
        <div>
          Bis:
          <StyledDatePicker
            selected={eventData.endTime}
            onChange={(date) => handleDateChange(date, 'endTime')}
            showTimeSelect={!isAllDay}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Zeit"
            dateFormat="Pp"
            locale="de"
          />
        </div>
      </DataContainerDatePicker>

      <DataContainer>
        <div>
          <input type="checkbox" id="ganzerTag" name="ganzerTag" checked={isAllDay} onChange={handleAllDayCheckbox} />
          <label htmlFor="ganzerTag">Ganzer Tag</label>
        </div>
        <div>
          <input type="checkbox" id="wiederholen" name="wiederholen" checked={showRepeatComponent} onChange={handleRepeatCheckbox} />
          <label htmlFor="wiederholen">Wiederholen</label>
        </div>
      </DataContainer>
      
      {showRepeatComponent && (
        <RepeatKomponent
          newEvent={{
            title: eventData.title,
            description: eventData.description,
            start: eventData.startTime,
            end: eventData.endTime,
            color: generateEventColor(eventData.selectedEmployees),
            selectedEmployees: eventData.selectedEmployees,
            startTimeEvent:eventData.startTime,
            endTimeEvent:eventData.endTime
          }}
          users={eventData.selectedEmployees}
          color={generateEventColor(eventData.selectedEmployees)}
          setShowEventForm={setShowEventEdit}
          setNewEvent={setNewEvent}
        />
      )}
      
      <Button onClick={handleEditButtonClick} showRepeatComponent={showRepeatComponent}>Änderungen Speichern</Button>
      <Button onClick={handleDeleteEvent} showRepeatComponent={showRepeatComponent}>Den Termin Löschen</Button>
    </FormContainer>
  );
}

export default EventEdit;
