
import React, { useContext, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';
import API from '../../context/axioSetup';
import { EmployeesContext, EventsContext } from '../../context/EveMitContext';
import {
    FormContainer,
    Header,
    FormHeader1,
    InputEv,
    SelectEmployee,
    MitarbeiterPicker,
    Button,
    DataContainerDatePicker
} from './EditSearch.element';

function EditSearch({ hoveredEvents, setShowEventEdit }) {
    const { employees } = useContext(EmployeesContext);
    const { allEvents, setAllEvents, fetchEventsAndUpdate } = useContext(EventsContext);
    console.log(hoveredEvents);
    

    const [eventData, setEventData] = useState({
        event_id: '',
        title: '',
        description: '',
        selectedEmployees: [],
        startTimeEvent: new Date(),
        endTimeEvent: new Date(),
        color: ''
    });

    useEffect(() => {
        if (hoveredEvents && hoveredEvents.length > 0) {
            const event = hoveredEvents[0];
            setEventData({
                event_id: event.event_id,
                title: event.title,
                description: event.description,
                color: event.color,
                selectedEmployees: event.employee_names || [],
                startTimeEvent: new Date(event.start_datetime),
                endTimeEvent: new Date(event.end_datetime),
            });
        }
    }, [hoveredEvents]);

    const generateEventColor = (selectedEmployees) => {
        const colors = selectedEmployees.map(emp => emp.color);
        if (colors.length === 1) {
            return colors[0];
        } else if (colors.length > 1) {
            return `linear-gradient(to right, ${colors.join(', ')})`;
        } else {
            return 'blue';
        }
    };

    const removeSelectedEmployee = (employeeName) => {
        const updatedSelectedEmployees = eventData.selectedEmployees.filter((emp) => emp.name !== employeeName);
        setEventData({ ...eventData, selectedEmployees: updatedSelectedEmployees });
    };

    const addSelectedEmployees = (e) => {
        const newSelectedEmployees = Array.from(e.target.selectedOptions, (option) => {
            const employee = employees.find(emp => emp.name === option.value);
            if (eventData.selectedEmployees.find(selectedEmp => selectedEmp.name === employee.name)) {
                return null;
            }
            const color = employee ? (employee.farbe.startsWith('#') ? employee.farbe : `${employee.farbe}`) : '#default-color';
            return {
                name: option.value,
                color: color,
                id: employee.mitarbeiter_id
            };
        }).filter(emp => emp !== null);
        setEventData({ ...eventData, selectedEmployees: [...eventData.selectedEmployees, ...newSelectedEmployees] });
    };

    const handleDateChange = (date, field) => {
        setEventData({ ...eventData, [field]: date });
    };

    const handleEditButtonClick = async () => {
        const formattedStartDate = format(eventData.startTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });
        const formattedEndDate = format(eventData.endTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });
    
        const editedEventData = {
            event_id: eventData.event_id,
            start: formattedStartDate,
            end: formattedEndDate,
            title: eventData.title,
            description: eventData.description,
            selectedEmployees: eventData.selectedEmployees,
            color: generateEventColor(eventData.selectedEmployees),
            hoveredEventIDs: hoveredEvents.map(event => event.event_id)
        };
    
        console.log('Edited Event Data:', editedEventData); // Debugging-Ausgabe
    
        try {
            const response = await API.post('/editWiederholendeTermine', editedEventData);
    
            if (response.status === 200) {
                console.log(response.data.message);
                alert('die Termine wurden erfolgreich geändert')
                // Update local events state
                const updatedEvents = allEvents.map(event => {
                    if (hoveredEvents.map(e => e.event_id).includes(event.event_id)) {
                        return {
                            ...event,
                            start_datetime: new Date(editedEventData.start),
                            end_datetime: new Date(editedEventData.end),
                            title: editedEventData.title,
                            description: editedEventData.description,
                            employee_names: editedEventData.selectedEmployees,
                            color: editedEventData.color
                        };
                    }
                    return event;
                });
                setAllEvents(updatedEvents);
                fetchEventsAndUpdate()

            } else {
                console.error('Fehler beim Bearbeiten der Events');
            }
        } catch (error) {
            console.error('Fehler beim Bearbeiten der Events:', error);
        }
    
        setShowEventEdit(false);
    };
    
const handleDeleteEvent = async () => {
    // Alle event_ids aus den hoveredEvents sammeln
    const eventIDs = hoveredEvents.map(event => event.event_id);
    console.log('hoveredEvents:', hoveredEvents);
    console.log('eventIDs to delete:', eventIDs);

    const isConfirmed = window.confirm('Möchten Sie diese Termine wirklich löschen?');
    if (isConfirmed) {
        try {
            const response = await API.delete('/deleteEventsRepeat', {
                headers: {
                    'Content-Type': 'application/json'
                },
                // Daten müssen explizit unter `data` im Body des Requests gesendet werden
                data: { eventIDs }
            });
            
            if (response.status === 200) {
                console.log(response.data.message);
                // Lokale Events-Status aktualisieren, entfernte Events filtern
                const updatedEvents = allEvents.filter(event => !eventIDs.includes(event.event_id));
                setAllEvents(updatedEvents);
                alert('Die Events wurden erfolgreich gelöscht');
                fetchEventsAndUpdate();
            } else {
                console.error('Fehler beim Löschen der Events');
            }
        } catch (error) {
            console.error('Fehler beim Löschen der Events:', error);
        }

        // Schließt das Bearbeitungsfenster nach dem Löschen
        setShowEventEdit(false);
    }
};

    

    return (
        <FormContainer>
            <FontAwesomeIcon icon={faTimes} onClick={() => setShowEventEdit(false)} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
            <Header>
                <FormHeader1>Bearbeite deinen Termin</FormHeader1>
            </Header>
            <InputEv type="text" placeholder="Titel" value={eventData.title} onChange={(e) => setEventData({ ...eventData, title: e.target.value })} />
            <InputEv
                type="text"
                placeholder="Description"
                value={eventData.description}
                onChange={(e) => setEventData({ ...eventData, description: e.target.value })}
            />
            <SelectEmployee
                multiple
                onChange={addSelectedEmployees}
            >
                {employees.map((employee) => (
                    <option key={employee.ID} value={employee.name} style={{ backgroundColor: `${employee.farbe}` }}>
                        {employee.name}
                    </option>
                ))}
            </SelectEmployee>
            <MitarbeiterPicker>
                {eventData.selectedEmployees.map((employee) => (
                    <span key={employee.name} style={{ display: 'flex', alignItems: 'center', backgroundColor: `${employee.color}` }}>
                        {employee.name}
                        <FontAwesomeIcon icon={faTimes} onClick={() => removeSelectedEmployee(employee.name)} />
                    </span>
                ))}
            </MitarbeiterPicker>
            <DataContainerDatePicker>
                <div>
                    Von:
                    <DatePicker
                        selected={eventData.startTimeEvent}
                        onChange={(date) => handleDateChange(date, 'startTimeEvent')}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Zeit"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        inputMode="numeric"  // Verhindert, dass Telefonnummern interpretiert werden
                        pattern="\d*"        // Akzeptiert nur numerische Eingaben
                      
                    />
                </div>
                <div>
                    Bis:
                    <DatePicker
                        selected={eventData.endTimeEvent}
                        onChange={(date) => handleDateChange(date, 'endTimeEvent')}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Zeit"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        inputMode="numeric"  // Verhindert, dass Telefonnummern interpretiert werden
                        pattern="\d*"        // Akzeptiert nur numerische Eingaben
                      
                    />
                </div>
            </DataContainerDatePicker>
            <Button onClick={handleEditButtonClick}>Änderungen Speichern</Button>
            <Button onClick={handleDeleteEvent}>Den Termin Löschen</Button>
        </FormContainer>
    );
}

export default EditSearch;
