import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import API from './axioSetup';
import AuthContext from './authContext';

export const EmployeesContext = createContext();
export const EventsContext = createContext();

export const EmployeesProvider = React.memo(({ children }) => {
  const { user } = useContext(AuthContext);
  const userId = user ? user.username : null;
  const [employees, setEmployees] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);

  useEffect(() => {
    if (!userId) return;

    const fetchEmployees = async () => {
      try {
        const response = await API.get(`/getMitarbeiterByUserId?userId=${userId}`);
        setEmployees(response.data);
        const ids = response.data.map((emp) => emp.mitarbeiter_id);
        setEmployeeIds(ids);
        console.log('Fetched employees:', response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Mitarbeiter:', error);
      }
    };

    fetchEmployees();
  }, [userId]);

  const employeesContextValue = useMemo(
    () => ({ employees, employeeIds, setEmployees }),
    [employees, employeeIds]
  );

  return (
    <EmployeesContext.Provider value={employeesContextValue}>
      {children}
    </EmployeesContext.Provider>
  );
});

export const EventsProvider = React.memo(({ children }) => {
  const { employeeIds, employees } = useContext(EmployeesContext);
  const [allEvents, setAllEvents] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
console.log('firstLog',filteredEmployees);

  const fetchEventsAndUpdate = async () => {
    if (employeeIds.length === 0) return;

    try {
      const response = await API.get('/showevents', {
        params: { employeeIds: JSON.stringify(employeeIds) },
      });

      const formattedEvents = response.data.map((event) => ({
        ...event,
        start_datetime: new Date(event.start_datetime),
        end_datetime: new Date(event.end_datetime),
      }));

      setAllEvents(formattedEvents);
      console.log('Fetched events:', formattedEvents);
    } catch (error) {
      console.error('Fehler beim Abrufen der Events:', error);
    }
  };

  useEffect(() => {
    fetchEventsAndUpdate();
    const intervalId = setInterval(fetchEventsAndUpdate, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [employeeIds]);

 // Adjust filtering logic in EventsProvider to fix reverse filtering
useEffect(() => {
  if (!employees) return;

  const newFilteredEvents = filteredEmployees.length
    ? allEvents.filter((event) => {
        const eventEmployeeIds = event.employee_names.map((emp) => emp.id);
        // Only include events if they match exactly with selected filteredEmployees
        return eventEmployeeIds.some((id) => filteredEmployees.includes(id));
      })
    : allEvents; // If no filteredEmployees, show all events

  setFilteredEvents(newFilteredEvents);
  console.log('Filtering applied:', { filteredEmployees, newFilteredEvents });
}, [allEvents, filteredEmployees, employees]);


  const eventsContextValue = useMemo(
    () => ({
      allEvents,
      filteredEvents,
      setFilteredEmployees,
      setFilteredEvents,
      fetchEventsAndUpdate,
    }),
    [allEvents, filteredEvents]
  );

  return (
    <EventsContext.Provider value={eventsContextValue}>
      {children}
    </EventsContext.Provider>
  );
});

export const AppContextProvider = ({ children }) => (
  <EmployeesProvider>
    <EventsProvider>{children}</EventsProvider>
  </EmployeesProvider>
);
