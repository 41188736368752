import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginComponent from './Login/LoginComponent';
import Home from './Home/Home';
import SearchEvent from './SearchComponent/SearchEvent';
import { LanguageProvider } from './context/LanguageContext';
import PrivateRoute from './PrivateRoute';
import { AppContextProvider} from './context/EveMitContext';

function App() {
  return (
    <LanguageProvider>
      <AppContextProvider>
          <Routes>
            {/* Redirect from root path to login */}
            <Route path="/" element={<Navigate to="/login" replace />} />

            {/* Define your actual login and private routes */}
            <Route path="/login" element={<LoginComponent />} />
            <Route 
              path="/user/:userId" 
              element={<PrivateRoute><Home /></PrivateRoute>} 
            />
            <Route 
              path="/user/:userId/search" 
              element={<PrivateRoute><SearchEvent /></PrivateRoute>} 
            />
            
            {/* Optional: Add a wildcard route to catch undefined paths */}
            {/* <Route path="*" element={<ErrorPage />} /> */}
          </Routes>
          </AppContextProvider>
    </LanguageProvider>
    
  );
}

export default App;
