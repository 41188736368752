import { useState } from 'react';

const useEmployeeSelection = (initialSelectedEmployees, employees) => {
  const [selectedEmployees, setSelectedEmployees] = useState(initialSelectedEmployees);



  const generateEventColor = (selectedEmployees) => {
    const colors = selectedEmployees.map(emp => emp.color); // Deine hex Farben wie #f16c20 etc.
    
    // Wenn nur ein Mitarbeiter beteiligt ist, wird dessen Farbe zurückgegeben
    if (colors.length === 1) {
      return colors[0];
    }
    
    // Wenn mehrere Mitarbeiter beteiligt sind, wird ein repeating-linear-gradient erstellt
    else if (colors.length > 1) {
      const gradientColors = colors.map((color, index) => {
        const position = index * 30; // z.B. 30px Schritt für jeden Farbblock
        return `${color} ${position}px, ${color} ${position + 30}px`;
      }).join(', ');
      
      return `repeating-linear-gradient(-45deg, ${gradientColors})`;
    } 
    
    // Wenn keine Mitarbeiter vorhanden sind, standardmäßig blau
    else {
      return 'blue';
    }
  };
 

  return {
    selectedEmployees,

    generateEventColor,
    setSelectedEmployees
  };
};

export default useEmployeeSelection;

