import React, { useContext, useState, useMemo, useCallback } from 'react';
import { EventsContext } from '../context/EveMitContext';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom'; // Verwende useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, DatePickerContainer, Title, StylishButton } from './SearchEvent.element';
import EventDetails from './EventDetail/EventDetails';

function SearchEvent() {
    const { allEvents } = useContext(EventsContext);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false); 
    const navigate = useNavigate(); // useNavigate-Hook

    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    };

    const handleSelectChange = useCallback(
        debounce((selected) => {
            setSelectedOptions(selected || []);
            setShowDetails(false);
        }, 300),
        []
    );

    const filteredEvents = useMemo(() => {
        let filtered = allEvents;
        if (startDate || endDate) {
            filtered = filtered.filter(event => {
                const eventDate = new Date(event.start_datetime);
                if (startDate && endDate) {
                    return eventDate >= startDate && eventDate <= endDate;
                } else if (startDate) {
                    return eventDate >= startDate;
                } else if (endDate) {
                    return eventDate <= endDate;
                }
                return true;
            });
        }

        if (selectedOptions.length > 0) {
            const selectedTitles = selectedOptions.map(option => option.event.title);
            filtered = filtered.filter(event => selectedTitles.includes(event.title));
        }

        return filtered;
    }, [allEvents, selectedOptions, startDate, endDate]);

    const eventOptions = useMemo(() => {
        return filteredEvents.map(event => ({
            value: event.event_id,
            label: `${event.title} - ${new Date(event.start_datetime).toLocaleString()} - ${event.employee_name}`,
            event
        }));
    }, [filteredEvents]);

    const handleShowDetails = () => {
        setShowDetails(true);
    };

    const goBack = () => {
        navigate(-1); // Navigiere zurück zur vorherigen Seite
    };

    return (
        <Container>
             <StylishButton onClick={goBack}>
                                <FontAwesomeIcon icon={faArrowLeft} /> Zurück
                            </StylishButton>
            <DatePickerContainer>
                <DatePicker
                    className="date-picker"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Startdatum auswählen"
                    dateFormat="dd/MM/yyyy"
                />
                <DatePicker
                    className="date-picker"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="Enddatum auswählen"
                    dateFormat="dd/MM/yyyy"
                />
            </DatePickerContainer>
            <Select
                options={eventOptions}
                onChange={handleSelectChange}
                isMulti
                isLoading={isLoading}
                placeholder="Suche nach Events"
            />
            {filteredEvents.length > 0 && (
                <>
                    <StylishButton onClick={handleShowDetails}>Details anzeigen</StylishButton>
                    {showDetails && (
                        <>
                            <Title>Ausgewählte Event Details:</Title>
                            <EventDetails filteredEvents={filteredEvents} />
                           
                        </>
                    )}
                </>
            )}
        </Container>
    );
}

export default SearchEvent;
