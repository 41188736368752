import React, { useEffect, useContext   } from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import EventForm from './EventForm';
import EventEdit from './EventEdit';
import useCalendarState from '../Hooks/useCalendarState';
import { LanguageContext } from '../context/LanguageContext';
import './calandar.css';
import { EventsContext} from '../context/EveMitContext'
import CustomToolbar from './CustomToolbar';
import { Tooltip } from 'react-tooltip';
import CustomEventTooltip from './CustomEventTooltip';

const CalendarWithDragAndDrop = withDragAndDrop(Calendar);
let allViews = Object.keys(Views).map((k) => Views[k]);
const locales = {
  de: deLocale,
  en: enLocale,
};

const getLocalizer = (language) => {
  const locale = locales[language];
  return dateFnsLocalizer({
    format: (date, formatStr, options) => format(date, formatStr, { ...options, locale }),
    parse: (dateStr, formatStr, options) => parse(dateStr, formatStr, new Date(), { ...options, locale }),
    startOfWeek: (date, options) => startOfWeek(date, { ...options, locale }),
    getDay: (date, options) => getDay(date, { ...options, locale }),
    locales: { [language]: locale },
  });
};

const getFormats = (language) => {
  return {
    timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'p', culture),
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'p', culture)} - ${localizer.format(end, 'p', culture)}`,
    agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'p', culture)} - ${localizer.format(end, 'p', culture)}`,
    dayFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    dateFormat: (date, culture, localizer) => localizer.format(date, 'dd', culture),
    monthHeaderFormat: (date, culture, localizer) => localizer.format(date, 'MMMM yyyy', culture),
    dayHeaderFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    agendaDateFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    monthFormat: (date, culture, localizer) => localizer.format(date, 'MMMM', culture),
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'eeeeee', culture),
  };
};

const messages = {
  de: {
    date: 'Datum',
    time: 'Uhrzeit',
    event: 'Ereignis',
    allDay: 'Ganztägig',
    week: 'Woche',
    work_week: 'Arbeitswoche',
    day: 'Tag',
    month: 'Monat',
    previous: 'Zurück',
    next: 'Weiter',
    yesterday: 'Gestern',
    tomorrow: 'Morgen',
    today: 'Heute',
    agenda: 'Agenda',
    noEventsInRange: 'Keine Ereignisse in diesem Zeitraum.',
    showMore: (total) => `+ Zeige mehr (${total})`,
  },
  en: {
    date: 'Date',
    time: 'Time',
    event: 'Event',
    allDay: 'All day',
    week: 'Week',
    work_week: 'Work week',
    day: 'Day',
    month: 'Month',
    previous: 'Back',
    next: 'Next',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    today: 'Today',
    agenda: 'Agenda',
    noEventsInRange: 'No events in this range.',
    showMore: (total) => `+ Show more (${total})`,
  },
};

function CalendarKomponent({ selectedDate, setSelectedDate }) {
  const {
    calendarRef,
    showEventForm,
    setShowEventForm,
    showEventEdit,
    setShowEventEdit,
    hoveredEvent,
    newEvent,
    setNewEvent,
    
    handleSelectSlot,
    handleEventHover,
    moveEvent,
    resizeEvent,
    eventPropGetter,
  
    view,
    setView,
  } = useCalendarState();

 
  const { language } = useContext(LanguageContext);
  const localizer = getLocalizer(language);
  const formats = getFormats(language);
  const calendarMessages = messages[language];
  const { filteredEvents} = useContext(EventsContext);



  useEffect(() => {
    const savedDate = localStorage.getItem('selectedDate');
    if (savedDate) {
      setSelectedDate(new Date(savedDate));
    }
  }, [setSelectedDate]);

  useEffect(() => {
    if (selectedDate) {
      localStorage.setItem('selectedDate', selectedDate.toISOString());
    }
  }, [selectedDate]);

  useEffect(() => {
    localStorage.setItem('calendarView', view);
  }, [view]);

  // Define the onShowMore function without useCallback
  const onShowMore = (events, date) => {
 
    // Or use a custom message if `buildMessage` is available
    // window.alert(buildMessage(events, date));
  };
 // Conditionally set calendar interactivity based on whether form/edit is shown

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <CalendarWithDragAndDrop
          ref={calendarRef}
          style={{ height: '100%', width: '100%', minHeight: '100vh' }}
          localizer={localizer}
          events={filteredEvents}
          formats={formats}
          messages={calendarMessages}
         // tooltipAccessor={tooltipAccessor}
          startAccessor={(event) => new Date(event.start_datetime)}
          endAccessor={(event) => new Date(event.end_datetime)}
          step={30}
          timeslots={2}
          views={allViews}
          view={view}
          onView={setView}
          onSelectSlot={handleSelectSlot}
          showMultiDayTimes={false}
          resizable
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          selectable
          draggable
          eventPropGetter={eventPropGetter}
          min={new Date('2023-07-20T07:00:00')}
          max={new Date('2023-07-20T21:00:00')}
          onSelectEvent={handleEventHover}
          date={selectedDate}
          onNavigate={setSelectedDate}
          dayLayoutAlgorithm={'no-overlap'}
          popup
          onShowMore={onShowMore} // Add the onShowMore prop here
          components={{
            toolbar: (props) => (
              <CustomToolbar
                {...props}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                view={view}
                messages={calendarMessages}
              />
            ),
            event: ({ event }) => {
              // Formatierte Start- und Endzeit für die Anzeige vorbereiten
              const startTime = format(new Date(event.start_datetime), 'HH:mm', { locale: deLocale });
             // const endTime = format(new Date(event.end_datetime), 'HH:mm', { locale: deLocale });
           
            
              return (
                <>
                  {/* Tooltip will now trigger when hovering over the entire event container */}
                  <div 
                    data-tooltip-id={`event-tooltip-${event.event_id}`} 
                    className="rbc-events-container"
                  >
                    {/* Anzeige von Start- und Endzeit sowie Titel */}
                    <div className="event-title">
                     <span className="event-start">{startTime}</span>  -{event.title}
                    </div>
                    {/* Other event details can be added here if needed */}
                  </div>
            
                  {/* Tooltip content */}
                  <Tooltip 
                    id={`event-tooltip-${event.event_id}`} 
             
                    effect="solid" 
                    offset={{ top: 10, left: 0 }}
                    >
                    <CustomEventTooltip event={event} />
                   </Tooltip>
                  </>
              );
            }
          }}
          
        />
      
      </DndProvider>

      {showEventForm && (
        <EventForm
          setShowEventForm={setShowEventForm}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
        />
      )}
      {showEventEdit && hoveredEvent && (
        <EventEdit
          hoveredEvent={hoveredEvent}
       
          setShowEventEdit={setShowEventEdit}
          setNewEvent={setNewEvent}
        />
      )}
    </>
  );
}

export default CalendarKomponent;

