import styled from 'styled-components';

export const EventContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

export const EventContent = styled.div`
    flex: 1;
`;

export const EventTitle = styled.h3`
    margin-bottom: 10px;
    color: #007bff;
`;

export const EventInfo = styled.p`
    margin: 5px 0;
    color: #555;
`;

export const EmployeeContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const EmployeeBadge = styled.span`
    background-color: ${props => props.color || '#ccc'};
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
`;

export const Divider = styled.hr`
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 20px 0;
`;

export const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const CheckboxInput = styled.input`
    margin-right: 8px;
`;
export const StylishButton = styled.button`
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
        background-color: #45a049;
    }
`;
