import axios from 'axios';
import { createContext } from 'react';

const APIContext = createContext();

const API = axios.create({
  baseURL: 'https://api.kalender.gronde.eu/api',
  withCredentials: true,
  timeout: 15000,
});

let refreshTokenInProgress = false;
let refreshTokenPromise = null;

// Funktion zur Erneuerung des Access Tokens
const refreshAccessToken = async () => {
  if (refreshTokenInProgress) {
    return refreshTokenPromise;
  }

  refreshTokenInProgress = true;
  refreshTokenPromise = API.post('/refresh-token')
    .then((response) => {
      console.log('from refreschtpken',response.data);
      
      const newAccessToken = response.data.accessToken;

      localStorage.setItem('access_token', newAccessToken);
    localStorage.setItem('session_id',response.data.sessionId);
      return newAccessToken;
    })
    .catch((error) => {
      console.error('Error refreshing access token:', error);
      localStorage.removeItem('access_token');
      //soll ich logout beim Fehler hier implementieren 
      throw error;
    })
    .finally(() => {
      refreshTokenInProgress = false;
      refreshTokenPromise = null;
    });

  return refreshTokenPromise;
};

// Request-Interceptor für Access Token
API.interceptors.request.use(
  (config) => {
    if (config.url !== '/refresh-token') {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response-Interceptor für Token-Erneuerung
API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return API(originalRequest);
      } catch (err) {
        console.error('Token refresh failed:', err);
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

const APIProvider = ({ children }) => (
  <APIContext.Provider value={API}>{children}</APIContext.Provider>
);

export { APIContext, APIProvider };
export default API;
