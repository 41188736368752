import { useState, useRef, useEffect, useContext } from 'react';
import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';
import API from '../context/axioSetup';
import { EmployeesContext, EventsContext } from './../context/EveMitContext';

const useCalendarState = () => {
  const calendarRef = useRef(null);
  const [showEventForm, setShowEventForm] = useState(false);
  const [showEventEdit, setShowEventEdit] = useState(false);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: '',
    end: '',
    startTimeEvent: '',
    endTimeEvent: '',
    users: [],
    color: '',
    description: '',
  });
  const [view, setView] = useState(() => {
    const savedView = localStorage.getItem('calendarView');
    return savedView || 'month';
  });

  const { employees } = useContext(EmployeesContext);
  const { allEvents, setAllEvents } = useContext(EventsContext);

  const handleSelectSlot = (slotInfo) => {
    const { start, end } = slotInfo;
    setNewEvent((prevState) => ({
      ...prevState,
      start: start,
      end: end,
      startTimeEvent: new Date(start),
      endTimeEvent: new Date(end),
    }));
    setShowEventForm(true);
    if (showEventEdit) {
      setShowEventForm(false);
      setShowEventEdit(false);
    }
  };

  const handleEventHover = (event) => {
    setShowEventEdit(true);
    setHoveredEvent(event);
  };
  

  const updateEvent = async ({ event, start, end }) => {
    try {
      if (event && (start !== undefined || end !== undefined)) {
        const updatedEvents = [...allEvents];
        const eventIndex = updatedEvents.findIndex((e) => e.event_id === event.event_id);
        if (eventIndex !== -1) {
          if (start !== undefined) updatedEvents[eventIndex].start_datetime = start;
          if (end !== undefined) updatedEvents[eventIndex].end_datetime = end;
          setAllEvents(updatedEvents);
          const formattedStartDate = start
            ? format(start, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale })
            : event.start_datetime;
          const formattedEndDate = end
            ? format(end, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale })
            : event.end_datetime;
          const eventData = {
            event_id: event.event_id,
            start: formattedStartDate,
            end: formattedEndDate,
          };
          saveEventChanges(eventData);
        }
      } else {
        console.error('Ungültige Parameter für Event-Aktualisierung:', { event, start, end });
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Events:', error);
    }
  };

  const moveEvent = ({ event, start, end }) => {
    updateEvent({ event, start, end });
  };

  const resizeEvent = ({ event, start, end }) => {
    if (!event || (!start && !end)) {
      setAllEvents(allEvents);
      return;
    }
    updateEvent({ event, start, end });
  };

  const saveEventChanges = (eventData) => {
    API.post('/saveeventchanges', eventData)
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error('Fehler beim Speichern der Event-Änderungen:', error);
      });
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    const eventBoxStyle = {
      background: event.color || '#007bff', // Dynamische Event-Hintergrundfarbe
      color: 'white',
      margin: '1px 1px',
      fontSize: '13px',
      borderRadius: '8px',
      padding: '4px 8px',
      transition: 'all 0.3s ease', // Übergang für sanftes Hovering
    };
  
    return {
      style: eventBoxStyle,
      start,
      end,
    };
  };
  
  const tooltipAccessor = (event) => {
    if (event) {
      const employeeNames = event.employee_names || [];
      const tooltipContent = `
        Title: ${event.title}
        Beschreibung: ${event.description}
        Start: ${format(new Date(event.start_datetime), 'PPPP HH:mm')}
        End: ${format(new Date(event.end_datetime), 'PPPP HH:mm')}
        MA: '${employeeNames.map((emp) => emp.name).join(', ')}
      `;
      return tooltipContent;
    }
    return '';
  };

  useEffect(() => {
    localStorage.setItem('calendarView', view);
  }, [view]);

  return {
    calendarRef,
    showEventForm,
    setShowEventForm,
    showEventEdit,
    setShowEventEdit,
    hoveredEvent,
    newEvent,
    setNewEvent,
    employees,
    allEvents,
    handleSelectSlot,
    handleEventHover,
    moveEvent,
    resizeEvent,
    eventPropGetter,
    tooltipAccessor,
    view,
    setView,
  };
};

export default useCalendarState;

