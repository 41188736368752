import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Segoe UI';
  margin-bottom: 10px;
  padding: 10px 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
`;

export const Logo = styled.img`
  height: 40px;


  @media (max-width: 768px) {
    height: 30px;
    margin-bottom: 10px;
    margin-left: 0;
  }
`;

export const HeaderTitle = styled.h1`
  color: #943534;
  margin: 0;
  font-size: 1.5em;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

export const LogoutButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: #943534;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #7c2a29;
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 8px 16px;
  }
`;

export const SearchButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: #943534;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #7c2a29;
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 8px 16px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;
