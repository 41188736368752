import React, { memo } from 'react';
import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';

const CustomEventTooltip = memo(({ event }) => {
  const employeeNames = event.employee_names || [];

  const startDate = new Date(event.start_datetime);
  const endDate = new Date(event.end_datetime);

  const formattedStart = format(startDate, 'EEE, d MMM yyyy HH:mm', { locale: deLocale });
  const sameDay = format(startDate, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd');
  const formattedEnd = sameDay
    ? format(endDate, 'HH:mm', { locale: deLocale })
    : format(endDate, 'EEE, d MMM yyyy HH:mm', { locale: deLocale });



  return (
    <div className="custom-tooltip">
      <div><strong>Titel: {event.title || 'N/A'}</strong></div>
      <div>{formattedStart} - {formattedEnd}</div>
      {event.description && <div>{event.description}</div>}
      {employeeNames.length > 0 && (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '2px' }}>
          {employeeNames.map((emp, index) => (
            <span
              key={index}
              style={{
                backgroundColor: emp.color || 'transparent',
                color: 'black',
                fontWeight: 'bold',
                padding: '8px 8px',
                borderRadius: '50%', // Make it circular
                display: 'inline-block',
                minWidth: '30px', // Ensures consistent circular appearance
                textAlign: 'center',
              }}
            >
              {emp.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
});

export default CustomEventTooltip;
