import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './picker.css'
import CalendarWithDragAndDrop from '../CalendarKomponent/CaledarKomponent';
import MitarbeiterList from '../MitarbeiterList/MitarbeiterList';
import { Container, CalendarStyleWrapper, List, Content, StyledDatePicker, StyledDatePickerContainer,SidebarWrapper} from './Home.element';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes } from '@fortawesome/free-solid-svg-icons';

function Home() {


  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = localStorage.getItem('selectedDate');
    return savedDate ? new Date(savedDate) : new Date();
  });
  const [isListVisible, setIsListVisible] = useState(true);

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  useEffect(() => {
    if (selectedDate) {
      localStorage.setItem('selectedDate', selectedDate.toISOString());
    }
  }, [selectedDate]);

  return (
    <Container>
      <Header />
      <div>  <FontAwesomeIcon 
            icon={isListVisible ? faTimes : faUser} 
            onClick={toggleListVisibility} 
            style={{ cursor: 'pointer', fontSize: '24px', color: '#2980b9' }} 
          /></div>
    
      <Content>
      <SidebarWrapper>
          
          {isListVisible && (
            <List>
              <StyledDatePickerContainer>
                <DatePicker
                 isClearable={true}
                  selected={selectedDate}
                  onChange={handleSelectDate}
                  inline
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </StyledDatePickerContainer>
              <MitarbeiterList  />
            </List>
          )}
       </SidebarWrapper>
        <CalendarStyleWrapper isListVisible={isListVisible.toString()}>
          <CalendarWithDragAndDrop
          // Verwende gefilterte Events
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </CalendarStyleWrapper>
      </Content>
    </Container>
  );
}

export default Home;
