import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
    max-width: 97vw;/* Ensures full width */
   overflow-y:none;
    height: 100vh; /* Optional: full height */
    box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  display: flex;
  flex-direction: column;



  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%; /* Full width of Container */
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%; /* Optional: full height */
  box-sizing: border-box;
  overflow-y: none;
  overflow-x:none;

  @media (max-width: 768px) {
    flex-direction: row;
    padding: 10px;
  }
`;

export const SidebarWrapper = styled.div`
  max-width: 15%;


  background-color: white;
  overflow-y: none;
  overflow-x:none;
  box-sizing: border-box;
  display:flex;
  justify-content:center;
  align-items:center;
flex-direction:row;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const List = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: white;
  box-sizing: border-box;

  /* Test styles for visibility */

  min-height: 200px; /* Ensures it takes up some space */

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 10px;
  }
`;


export const CalendarWrapper = styled.div`
  flex: 1;
  max-width: 85%;
  height: 100%;
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const CalendarStyleWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 8px;

  .rbc-calendar {
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Other styles like StyledDatePickerContainer and StyledDatePicker remain unchanged



export const StyledDatePickerContainer = styled.div`
  width: 100%; /* Begrenze die Breite auf 80% */
  @media (max-width: 768px) {
    width: 100%; /* Volle Breite auf kleineren Bildschirmen */
    padding: 8px;
  }
`;

