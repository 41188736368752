import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../context/axioSetup';
import AuthContext from '../context/authContext';
import { LanguageContext } from '../context/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  SearchButton,
  LogoutButton,
  Logo,
  HeaderTitle,
  LeftContainer,
  RightContainer
} from './Header.element';
import logo from '../../src/Logo/Gronde_Logo_rot.jpg';

function Header() {
  const [filialeName, setFilialeName] = useState('');
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext); // Verwende `user` statt `currentUser`
  const { language, changeLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (user) {
      API.get(`/filiale?userId=${user.username}`) // Verwende `user.username`
        .then((response) => {
         
          setFilialeName(response.data.name);
        })
        .catch((error) => console.error('Fehler beim Abrufen des Filialnamens:', error));
    }
  }, [user]); // Verwende `user` als Abhängigkeit

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const goToSearch = () => {
    if (user && user.username) {
      navigate(`/user/${user.username}/search`);
    } else {
      console.error('No user or username available');
    }
  };

  return (
    <Container>
      <LeftContainer>
        <SearchButton onClick={goToSearch}>
          <FontAwesomeIcon icon={faSearch} />
        </SearchButton>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <select 
            value={language} 
            onChange={(e) => changeLanguage(e.target.value)}
            style={{ marginRight: '10px', padding: '5px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }}
          >
            <option value="de">Deutsch</option>
            <option value="en">English</option>
          </select>
        </div>
      </LeftContainer>
      <RightContainer>
        {filialeName && <HeaderTitle>Filliale: {filialeName}</HeaderTitle>}
        <Logo src={logo} alt="Logo" />
      </RightContainer>
    </Container>
  );
}

export default Header;
